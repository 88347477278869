import Layout from '@/components/Layout/index.vue';
import { UserRole } from '@/constants';
// icon:https://ant.design/components/icon
export const menuRoutes = [
  // 所有角色都有的路由
  {
    path: '/home',
    name: '首页',
    meta: { permissions: false, icon: 'home' },
    component: () => import('../views/Home/index.vue')
  },
  {
    path: '/statistics',
    name: '指标统计',
    meta: { permissions: [UserRole.ADMIN, UserRole.SYSTEM_HIVE], icon: 'pie-chart' },
    component: () => import('../views/Statistics/index.vue')
  },
  {
    path: '/StatisticsList',
    name: 'StatisticsList',
    meta: { permissions: [], icon: 'bar-chart' },
    component: () => import('../views/Statistics/components/StatisticsList.vue')
  },
  {
    path: '/beeList',
    name: '设备任务',
    meta: { permissions: [UserRole.ADMIN, UserRole.SYSTEM_HIVE], icon: 'android' },
    component: () => import('../views/BeeList/index.vue')
  },
  {
    path: '/beeAccountList',
    name: '账号任务',
    meta: { permissions: [UserRole.AGENT, UserRole.ADMIN, UserRole.SYSTEM_HIVE], icon: 'facebook' },
    component: () => import('../views/BeeAccountList/index.vue')
  },
  {
    path: '/create',
    name: '系统任务',
    meta: { permissions: [UserRole.ADMIN, UserRole.SYSTEM_HIVE], icon: 'laptop' },
    component: () => import('../views/SysTaskList/index.vue')
  },
  {
    path: '/list',
    name: '任务列表',
    meta: { permissions: false, icon: 'history' },
    component: () => import('../views/TaskList/index.vue')
  },
  {
    path: '/bee-and-account',
    name: '设备账号管理',
    meta: { permissions: [UserRole.ADMIN, UserRole.SYSTEM_HIVE], icon: 'android' },
    component: () => import('@/views/BeeAndAccount/index.vue')
  },
  {
    path: '/in-account',
    name: '账号任务视图',
    meta: { permissions: [UserRole.ADMIN, UserRole.SYSTEM_HIVE], icon: 'profile' },
    component: () => import('../views/TaskInAccount/index.vue')
  },
  {
    path: '/account/anti-rule',
    name: '收录异常上报',
    meta: { permissions: [UserRole.ADMIN, UserRole.SYSTEM_HIVE, UserRole.AGENT], icon: 'dislike' },
    component: () => import('../views/AntiRuleReport/index.vue')
  },
  {
    path: '/user/management',
    name: '用户管理',
    meta: { permissions: [UserRole.ADMIN, UserRole.SYSTEM_HIVE], icon: 'team' },
    component: () => import('../views/UserManagement/index.vue')
  },
  {
    path: 'user/edit',
    name: '编辑用户',
    meta: {
      permissions: []
    },
    component: () => import('../views/UserManagement/EditUser.vue')
  },
  {
    path: '/list/review',
    name: '审核列表',
    meta: { permissions: [UserRole.ADMIN, UserRole.SYSTEM_HIVE], icon: 'check' },
    component: () => import('../views/ReviewList/index.vue')
  },
  {
    path: '/task',
    name: '任务管理',
    meta: { permissions: [], icon: 'shopping' },
    component: () => import('../views/Task/ParentLayout.vue'),
    children: [
      {
        path: 'create',
        name: '创建任务',
        meta: { permissions: false, icon: 'tag' },
        component: () => import('../views/SysTaskList')
      },
      {
        path: 'task-detail',
        name: '任务详情',
        meta: {
          permissions: [UserRole.ADMIN, UserRole.SYSTEM_HIVE, UserRole.AGENT],
          icon: 'profile',
          hideInMenu: true // 在菜单中隐藏此路由
        },
        component: () => import('../views/TaskDetail/index.vue')
      },
      {
        path: 'list',
        name: 'TaskList',
        meta: { permissions: false, icon: 'list' },
        component: () => import('../views/TaskList/index.vue')
      }
    ]
  },
  {
    path: '/userProject',
    name: '项目价格',
    meta: { permissions: [UserRole.AGENT], icon: 'calculator' },
    component: () => import('../views/UserProject/index.vue')
  },
  {
    path: '/beeUsbRecord',
    name: 'USB 记录',
    meta: { permissions: [UserRole.ADMIN, UserRole.SYSTEM_HIVE], icon: 'usb' },
    component: () => import('../views/UsbRecordList/index.vue')
  },
  {
    path: '/fileUpload',
    name: '上传文件',
    meta: { permissions: [UserRole.ADMIN, UserRole.SYSTEM_HIVE], icon: 'upload' },
    component: () => import('../views/FileUpload/index.vue')
  },
  {
    path: '/conveyUpload',
    name: '搬运资料',
    meta: { permissions: [UserRole.ADMIN, UserRole.SYSTEM_HIVE], icon: 'cloud' },
    component: () => import('../views/Convey/index.vue')
  },
  {
    path: '/DFZF',
    name: 'DFZF',
    meta: { permissions: [], icon: 'facebook' },
    component: () => import('../views/TaskCreation/xhs/DFZF.vue')
  },
  {
    path: '/Shua',
    name: 'Shua',
    meta: { permissions: [], icon: 'facebook' },
    component: () => import('../views/TaskCreation/xhs/OldShua.vue')
  },
  {
    path: '/NewShua',
    name: 'NewShua',
    meta: { permissions: [], icon: 'facebook' },
    component: () => import('../views/TaskCreation/xhs/NewShua.vue')
  },
  {
    path: '/Update',
    name: 'Update',
    meta: { permissions: [], icon: 'facebook' },
    component: () => import('../views/TaskCreation/bee/Update.vue')
  }
];

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: menuRoutes
  },
  {
    path: '/login',
    component: () => import('../views/Login/index.vue')
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "404" */ '../views/NotFoundPage/index.vue')
  },
  {
    path: '/errorPage',
    component: () => import(/* webpackChunkName: "errorPage" */ '../views/ErrorPage/index.vue')
  },
  {
    path: '/task-in-account',
    component: () => import('@/views/TaskInAccount/index.vue'),
    meta: {
      title: '账号任务',
      icon: 'profile',
      roles: [UserRole.SYSTEM_HIVE, UserRole.ADMIN] // 只允许系统管理员和管理员访问
    }
  },
  { path: '*', redirect: '/home' }
];
export default routes;
