export const UserRole = {
  SYSTEM_HIVE: 0,
  ADMIN: 1,
  AGENT: 2,
  USER: 3
};

export const DailyLimits = {
  DAY_MAX_COMMENT_COUNT: 5,
  DAY_MAX_LIKE_COUNT: 5,
  DAY_MAX_COLLECT_COUNT: 5,
  DAY_MAX_FANS_COUNT: 5,
  DAY_MAX_PUBLISH_COUNT: 1
};

export const labelCol = { span: 6 };
export const wrapperCol = { span: 14 };

export const TaskType = {
  // -1xx 待开发任务
  TASK_XHS_LIVE: { code: -100, description: '小红书直播场控' },

  // 0 默认
  TASK_UNKNOWN: { code: 0, description: 'UNKNOWN' },

  // 1xx 系统命令
  TASK_SYS_UPDATE_AUTOJS: { code: 101, description: '更新脚本' },
  TASK_SYS_OPEN_USB: { code: 102, description: '【System】打开 USB（慎重）' },

  // 2xx 管理员常用命令
  TASK_XHS_TYPE_EVERY_ACCOUNT_DF_SUCCESS: { code: 200, description: '🍠 一键截图成功' },
  TASK_XHS_ZI_FA_YANG: { code: 201, description: '🍠  自发' },
  TASK_XHS_TYPE_ACCOUNT_YANG: { code: 202, description: '🍠 养号' },

  // 3xx 小红书命令
  TASK_XHS_SHUA: { code: 300, description: '🍠 刷量' },
  TASK_XHS_DAI_FA_APPLY_BEE_SCREENSHOT: { code: 301, description: '申请 🍠 账号' },
  TASK_XHS_DAI_FA: { code: 302, description: '🍠 代发' },
  TASK_XHS_SCREEN_SHOT: { code: 303, description: '🍠 更新主页截图' },
  TASK_XHS_SET_PROFILE: { code: 304, description: '🍠 更新身份' },
  TASK_XHS_DF_ZF_EDIT: { code: 305, description: '🍠 编辑笔记' },
  TASK_XHS_DF_ZF_DELETE: { code: 306, description: '🍠 删除笔记' },
  TASK_XHS_DF_ZF_HIDE: { code: 307, description: '🍠 隐藏笔记' },
  TASK_XHS_WEI_GUI_CLEAR: { code: 308, description: '🍠 违规笔记清理' }
};

export const TaskStatus = {
  TASK_STATUS_UNKNOWN: { code: 0, description: 'UNKNOWN' },
  TASK_STATUS_READY: { code: 1, description: '准备中' },
  TASK_STATUS_CREATING: { code: 2, description: '创建中' },
  TASK_STATUS_CREATED: { code: 3, description: '创建完毕' },
  TASK_STATUS_SUCCEED: { code: 4, description: '执行成功' },
  TASK_STATUS_SUCCEED_PART: { code: 5, description: '部分成功' },
  TASK_STATUS_SUCCEED_PART_WEI_GUI: { code: 501, description: '发文成功但平台判定违规' },
  TASK_STATUS_FAILED: { code: 6, description: '执行失败' },
  TASK_STATUS_RESULT_USED: { code: 7, description: '任务账号已被消耗' },
  TASK_STATUS_UN_REVIEW: { code: 8, description: '沟通号主中，约 15 分钟执行' },
  TASK_STATUS_REVIEW_ING: { code: 9, description: '管理员正在确认发送情况' }
};

export const BeePlatformType = {
  BEE_PLATFORM_UNKNOWN: { code: 0, description: '未知' },
  BEE_PLATFORM_XHS: { code: 1, description: '小红书' },
  BEE_PLATFORM_DY: { code: 2, description: '抖音' },
  BEE_PLATFORM_KS: { code: 3, description: '快手' }
};

export const ProjectType = {
  ALL: { code: 0, description: '任务列表筛选用' },
  BEE: { code: 1, description: '设备任务用' },
  BEE_ACCOUNT: { code: 2, description: '账号任务用' },
  HIDDEN_ADMIN: { code: 4, description: '系统列表用' }
};

// 使用示例
console.log(TaskType.TASK_XHS_LIVE); // { code: -100, description: "小红书直播场控" }
